@mixin backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, .5);
}

.Backdrop {
    @include backdrop;
}

.Alert {
    @include backdrop;
    z-index: 5000;
    display: flex;
    justify-content: center;

    &-content {
        text-align: center;
        margin-top: 70px;
        background-color: #fff;
        width: 70%;
        height: max-content;
        padding: 0 20px 20px 20px;
        border-radius: 5px;

        & p {
            line-height: 1;
        }

        @media screen and (min-width: 900px) {
            width: 600px;
        }

        & h1 {
            font-size: 18px;
        }
    }
}

.CountryAlert {
    @include max-width-height;
    @include backdrop;
    z-index: 10000; 
    @include flex-center;
    background-color: rgba(0, 0, 0, .7);
}

.Choose {
    text-align: center;
    width: 70%;
    background-color: #fff;
    transform: translateY(-30%);
    padding: 0 20px 20px 20px;

    &-Flag {
        display: flex;
        justify-content: center;

        &-imgHolder {
            margin: 15px;
            & img { width: 75px; height: 75px; }
        }

    }
    & p {
       color: $gold-hard;
       font-size: 18px;
    }
}